<template>
  <div class="manufacturerAdvertising">
    厂家广告
  </div>
</template>

<script>
export default {
  name: "manufacturerAdvertising"
}
</script>

<style lang="scss">
.manufacturerAdvertising{

}
</style>